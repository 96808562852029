<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_from">Date From</label>
            <b-input
              id="date_from"
              v-model="filter.date_from"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_from">Date To</label>
            <b-input
              id="date_from"
              v-model="filter.date_to"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :items="myProvider"
        :fields="tableSettings.fields"
        show-empty
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>
      </b-table>
    </b-card>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SupervisorReportService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'SupervisorSMSSummaries',

  middleware: ['auth', 'supervisor'],

  metaInfo () {
    return {
      title: 'Farmer Reports - SMS Summaries'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false
      },
      filter: {
        date_from: this.dateFormatter(Date.now()),
        date_to: this.dateFormatter(Date.now())
      },
      tableSettings: {
        fields: [
          'index',
          { key: 'promotion_name' },
          { key: 'total_sms' },
          { key: 'processing' },
          { key: 'sent' },
          { key: 'failed' }
        ]
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await SupervisorReportService.getSMSSummaries(
          this.objectToUrl({
            filter_from: this.filter.date_from,
            filter_to: this.filter.date_to
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    onFilterChange () {
      this.$refs.table.refresh()
    }
  }
}
</script>
